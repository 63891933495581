import React from "react";

const FlatLabelCard = ({label, children})=>{
    return (
        <div className="flat-label-card">
            <span className="label">{label}</span>
            {children}
        </div>
    )
}

const HomeTabDescription = ({label, description, onClick})=>{
    return (
        <FlatLabelCard label={label}>
            <div className="top">
                <p>{description}</p>
            </div>
            <div className="bottom">
                <button className="colored" onClick={onClick}>{label}</button>
            </div>
        </FlatLabelCard>
    )
}

export {
    FlatLabelCard,
    HomeTabDescription
}