import * as React from "react"
import { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import "../css/index.scss"
import Seo from "../components/seo"
import SvgDefinitions from "../components/SvgDefinitions"
import Footer from "../components/Footer"
import { HomeTabDescription } from "../components/FlatLabelCard"

import logos from "../components/CodingLogos"
import ProjectLogos from "../components/ProjectLogos"

const LabelHeading = ({primary, secondary})=>{
    return (
        <h2><div className="primary">{primary}</div><div className="secondary">{secondary}</div></h2>
    )
}

const TabAboutMe = ({tabTrigger}) => (
    <div className="active" tab-content="about-me">
        {/* <!-- ABOUT ME CARD --> */}
        <div className="about-me-card">
            <div className="text">
                <h1>About Me</h1>
                <hr/>
                <p>I am a recent Electrical and Biomedical Engineering Graduate (2020)
                    of the University of New South Wales (UNSW), with a long lived
                    interest in Neuroprosthetics. My Masters Thesis involved analyzing
                    Visual Cortex Responses to Stimulation by an Epiretinal Bionic Eye.
                    I have a love for learning and a passion for experimentation,
                    which has led me to grow my knowledge and skillset across a wide
                    domain of Hardware and Software Applications.
                </p>
            </div>
            <hr className="vertical" />
            <div className="pic-container">
                <div className="profile-pic-frame">
                    <StaticImage src="../images/profPicAlpha500.png" alt="Mihailo Evans Img" width={350} quality={95} formats={["auto", "webp", "avif"]}/>
                </div>
            </div>
        </div>
        {/* <!-- LINKS CARDS --> */}
        <div className="link-cards">
            {/* <!-- PROJECTS --> */}
            <HomeTabDescription 
                label="Projects"
                description="Go and take a look at some of the things I've been making."
                onClick={()=>{tabTrigger('Projects')}}
            />
            {/* <!-- RESUME --> */}
            <HomeTabDescription 
                label="Résumé"
                description="See my professional Experience and Skillset."
                onClick={()=>{tabTrigger('Resume')}}
            />
            {/* <!-- CONTACT --> */}
            <HomeTabDescription 
                label="Contact"
                description="Come and Say Hello."
                onClick={()=>{tabTrigger('Contact')}}
            />
        </div>
    </div>
)

const TabResume = () => (
    <div tab-content="resume" className="active">
        {/* <!-- Education --> */}
        <h1>Education</h1>
        <LabelHeading primary="2015 – 2020" secondary="University of New South Wales" />
        <p className="minor">Sydney, Australia</p>
        <p>
            Bachelors of Electrical Engineering (Honours)<br/>
            Masters of Biomedical Engineering
        </p>
        <br/>

        {/* <!-- Publications --> */}
        <h1>Publications</h1>
        <div className="publication-card">
            <div className="top">             
                <div className="title">
                    In vivo feasibility of epiretinal stimulation using ultrananocrystalline diamond electrodes.
                </div>
                <div className="authors">
                    Shivdasani, M.N., Evans, M., Burns, O., Yeoh, J., Allen, P.J., Nayagam, D.A., Villalobos,
                    J., Abbott, C.J., Luu, C.D., Opie, N.L. and Sabu, A., 2020.
                </div>
            </div>
            <div className="published">
                Journal of Neural Engineering, 17(4), p.045014.
            </div>
        </div>
        <br/>

        {/* <!-- Experience --> */}
        <h1>Experience</h1>
        <LabelHeading primary="2020 – 2021" secondary="Designability" />
        <h3>Remote Engineer</h3>
        <p>Fullstack Developer of Software Interfaces for the VP3 Calculator and PowerSTEM line of products</p>
        <ul>
            <li>Development of Web/Electron/NodeJS Applications</li>
            <li>Utilised Web Serial to allow for serial communication to hardware via the browser</li>
            <li>Database Management and Content Delivery via Google Firebase Integration</li>
            <li>Persistent Communication with the client actively influenced designs, by translating technical constraints and liberties, and providing creative solutions.</li>
        </ul><br/>

        <LabelHeading primary="2019 – 2020" secondary="Freshtracks" />
        <h3>Remote Electrical Engineer</h3>
        <p>Development of PIC32 and ESP C++ firmware for a therapeutic VR product</p>
        <ul>
            <li>Realtime acquisition of RFID and User Input Control data, transmitted as UDP packets over WiFi to the mobile device hosting the VR App.  </li>
            <li>Developed a Web Server on the ESP8266 for the purpose of acquiring user WiFi network credentials, required for the ESP to join the local network  </li>
            <li>Performed Schematic Design Review</li>
        </ul><br/>

        <LabelHeading primary="2018 – 2019" secondary="University of New South Wales" />
        <p className="minor">Sydney, Australia</p>
        <h3>Research Assistant</h3>
        <p>Hired to continue my Masters Thesis Project: Analysis of data from multiple In Vivo experiments gathering
            Visual Cortex Responses to stimulation of an Epiretinal Bionic Eye, using MATLAB extensively. The design
            of the prosthetic incorporated a high-density diamond electrode array, with aims of achieving precise
            phosphenes with low-charge stimulations. Conclusions on the feasibility and acuity of the prosthetic
            were drawn and outlined in the publication above.
        </p><br/>

        <LabelHeading primary="2018 – 2019" secondary="Fledge Innovation Laboratories" />
        <p className="minor">CSIRO, Sydney, Australia</p>
        <h3>Summer Engineering Internship</h3>
        <p>Fledge is a Biomedical Startup Incubator, which provided me with the opportunity to work in a multidisciplinary team of engineers across 2 biomedical startup companies.<br/></p>
        <ul>
            <li>Development of C++ firmware for PIC32 microcontrollers (coding SPI, and UART protocols to allow for communication with a peripheral ADS1298)</li>
            <li>Development of a Java based (Processing) GUI to display wearable tech data outputs</li>
        </ul><br/>

        {/* <!-- Professional Skillset --> */}
        <h1>Professional Skillset</h1>
        <p>Programming Proficiency in:</p>
        <div className="prog-lang-list">
            <div className="prog-lang">
                <img src={logos.progLang.c} alt="C Icon" />
                <p>C</p>
            </div>
            <div className="prog-lang">
                <img src={logos.progLang.csharp} alt="C# Icon" />
                <p>C#</p>
            </div>
            <div className="prog-lang">
             <img src={logos.progLang.cpp} alt="C++ Icon" />
                <p>C++</p>
            </div>
            <div className="prog-lang">
                <img src={logos.progLang.matlab} alt="MATLAB Icon" />
                <p>MATLAB</p>
            </div>
            <div className="prog-lang">
                <img src={logos.progLang.python} alt="Python Icon" />
                <p>Python</p>
            </div>
            <div className="prog-lang">
                <img src={logos.progLang.javascript} alt="JavaScript Icon" />
                <p>JavaScript</p>
            </div>
            <div className="prog-lang">
                <img src={logos.progLang.html} alt="HTML Icon" />
                <p>HTML</p>
            </div>
            <div className="prog-lang">
                <img src={logos.progLang.css} alt="CSS Icon" />
                <p>CSS</p>
            </div>
        </div>
        <p>Experience With Frameworks:</p>
        <div className="prog-lang-list">
            <div className="prog-lang">
                <img src={logos.frameworks.firebase} alt="Firebase Icon" />
                <p>Firebase</p>
            </div>
            <div className="prog-lang">
            <img src={logos.frameworks.nodejs} alt="NodeJS Icon" />
                <p>NodeJS</p>
            </div>
            <div className="prog-lang">
                <img src={logos.frameworks.react} alt="React Icon" />
                <p>React</p>
            </div>
            <div className="prog-lang">
                <img src={logos.frameworks.gatsby} alt="Gatsby Icon" />
                <p>Gatsby</p>
            </div>
            <div className="prog-lang">
                <img src={logos.frameworks.electron} alt="Electron Icon" />
                <p>Electron</p>
            </div>
            <div className="prog-lang">
                <img src={logos.frameworks.dotnet} alt="Dotnet Icon" />
                <p>.NET</p>
            </div>
        </div>
        <p>Experience With Version Control:</p>
        <div className="prog-lang-list">
            <div className="prog-lang">
                <img src={logos.versionControl.git} alt="Git Icon" />
                <p>Git</p>
            </div>
            <div className="prog-lang">
                <img src={logos.versionControl.github} alt="GitHub Icon" />
                <p>GitHub</p>
            </div>
        </div>
        <ul>
            <li>PCB Design – experience with Altium and KiCad</li>
            <li>Soldering of through-hole and surface-mount components</li>
            <li>Experience with hardware prototyping platforms: Arduino, Raspberry Pi</li>
            <li>Unity Engine Development</li>
            <li>FDM 3D Printing</li>
        </ul>
    </div>
)

const TabContact = () => (
    <div tab-content="contact" className="active">
        <h1>Contact</h1>
        <div className="link-stack">
            <a href="mailto:mihailoevans.contact@gmail.com" >
                <div className="img-wrapper">
                    <span className="material-icons">email</span>
                </div>
                <span>Email</span>
            </a>
            <a href="https://linkedin.com/in/mihailo-evans/" >
                <div className="img-wrapper">
                    <svg viewBox="0 0 578.952 578.952"><use href="#linkedin"/></svg>
                </div>
                <span>Linkedin</span>
            </a>
            <a href="https://instagram.com/mihailo_evans/" >
                <div className="img-wrapper">
                    <svg viewBox="0 0 512 512"><use href="#instagram"/></svg>
                </div>
                <span>Instagram</span>
            </a>
        </div>
    </div>
)

const TabProjects = () => (
    <div tab-content="projects" className="active">
        <div className="card-grid">
            {/* IMG2ASCII */}
            <div className="card">
                <div className="img-wrapper">
                    <img src={logos.frameworks.firebase} alt="Firebase Icon"/>
                </div>
                <div className="title">
                    <h1>IMG2ASCII</h1>
                    <h2>Web App | <a href="https://img-2-ascii.web.app">img-2-ascii.web.app</a></h2>
                </div>
                <div className="description">
                    <p>Turn an image into ASCII art in just a few simple steps</p>
                </div>
                <div className="link-bar">
                    <span className="material-icons">link</span>
                    <button><Link to="/project_IMG2ASCII/">Read More</Link></button>
                    <button><a href="https://img-2-ascii.web.app">Go To App</a></button>
                </div>
            </div>
            {/* WEB SERIAL MONITOR */}
            <div className="card">
                <div className="img-wrapper">
                    <img src={logos.frameworks.firebase} alt="Firebase Icon"/>
                </div>
                <div className="title">
                    <h1>Web Serial Monitor</h1>
                    <h2>Web App | <a href="https://web-serial-monitor.web.app">web-serial-monitor.web.app</a></h2>
                </div>
                <div className="description">
                    <p>Debug and Analyse hardware serial connections <b>in the browser</b></p>
                </div>
                <div className="link-bar">
                    <span className="material-icons">link</span>
                    <button>Read More</button>
                    <button><a href="https://web-serial-monitor.web.app">Go To App</a></button>
                </div>
            </div>
            {/* FOR ME TO DO */}
            <div className="card">
                <div className="img-wrapper">
                    <img src={ProjectLogos.ForMeToDo} alt="For Me To Do Icon"/>
                </div>
                <div className="title">
                    <h1>For Me To Do</h1>
                    <h2>PWA | <a href="https://for-me-to-do.web.app">for-me-to-do.web.app</a></h2>
                </div>
                <div className="description">
                    <p>A React ToDo List PWA with some handy features.</p>
                </div>
                <div className="link-bar">
                    <span className="material-icons">link</span>
                    <button><Link to="/project_ForMeToDo/">Read More</Link></button>
                    <button><a href="https://for-me-to-do.web.app">Go To App</a></button>
                </div>
            </div>
        </div>
    </div>
)

const IndexPage = () => {
    const [tab, setTab] = useState('About Me');

    return (<>
        <Seo title="Home" />
        <SvgDefinitions />
        <div className="content">
            <header>
                <div className="title-row">
                    <h1>MIHAILO EVANS</h1>
                    <div className="menubar">
                        <button id="resume" className={tab==='Resume' ? 'active' : ''} onClick={()=>{setTab('Resume')}}>Résumé</button>
                        <button id="projects" className={tab==='Projects' ? 'active' : ''} onClick={()=>{setTab('Projects')}}>Projects</button>
                        <button id="contact" className={tab==='Contact' ? 'active' : ''} onClick={()=>{setTab('Contact')}}>Contact</button>
                        <button id="about-me" className={tab==='About Me' ? 'active' : ''} onClick={()=>{setTab('About Me')}}>About Me</button>
                    </div>
                </div>
                <div className="subtitle">
                    <h2>Electrical / Biomedical Engineer</h2>
                    <span></span>
                </div>
            </header>

            <div id="lSide">
                <div className="vert-title-line" tab-content="resume">
                    <h2>Résumé</h2><span></span>
                </div>
            </div>
            <div id="mid">
                { tab==='About Me' ? (<TabAboutMe tabTrigger={setTab}/>) : ('') }
                { tab==='Resume' ? (<TabResume />) : ('') }
                { tab==='Contact' ? (<TabContact />) : ('') }
                { tab==='Projects' ? (<TabProjects />) : ('') }
            </div>
            <div id="rSide"></div>

            <Footer />
        </div>
    </>)
}

export default IndexPage
